<template lang='pug'>
    div
        div(v-if='isWiadvance()')
            v-menu(v-if='page!="Activity" && isStaff && !$isViewOnly.isViewOnly' offset-y)
                template(v-slot:activator="{ on, attrs }")
                    v-btn.ml-3.pa-0(text height='38' v-bind="attrs" v-on="on" color='gary' :disabled='page == "Opportunity" || page == "Case" ? selectStageType.id == "all" : false')
                        v-icon(size='16').mr-2 fas fa-file-upload
                        span {{$t('IMPORT_EXPORT.IMPORT')}}
                v-list
                    v-list-item(@click='importData') {{ $t('IMPORT_EXPORT.TITLE') }}
                    v-list-item(@click='downloadTemp') {{ $t('IMPORT_EXPORT.DOWNLOAD_TEMP') }}
            v-menu(v-if='page!="Activity" && isStaff && $isViewOnly.isViewOnly && page == "Opportunity"' offset-y)
                template(v-slot:activator="{ on, attrs }")
                    v-btn.ml-3.pa-0(text height='38' v-bind="attrs" v-on="on" color='gary' :disabled='page == "Opportunity" || page == "Case" ? selectStageType.id == "all" : false')
                        v-icon(size='16').mr-2 fas fa-file-upload
                        span {{$t('IMPORT_EXPORT.IMPORT')}}
                v-list
                    v-list-item(@click='importData') {{ $t('IMPORT_EXPORT.TITLE') }}
                    v-list-item(@click='downloadTemp') {{ $t('IMPORT_EXPORT.DOWNLOAD_TEMP') }}
            v-menu(v-if='page!="Activity" && !isStaff && !$isViewOnly.isViewOnly && page == "Opportunity"' offset-y)
                template(v-slot:activator="{ on, attrs }")
                    v-btn.ml-3.pa-0(text height='38' v-bind="attrs" v-on="on" color='gary' :disabled='page == "Opportunity" || page == "Case" ? selectStageType.id == "all" : false')
                        v-icon(size='16').mr-2 fas fa-file-upload
                        span {{$t('IMPORT_EXPORT.IMPORT')}}
                v-list
                    v-list-item(@click='importData') {{ $t('IMPORT_EXPORT.TITLE') }}
                    v-list-item(@click='downloadTemp') {{ $t('IMPORT_EXPORT.DOWNLOAD_TEMP') }}
            v-btn.ml-3.pa-0(v-hide-element='false' text height='38' color='gary' @click='exportData' :disabled='page == "Opportunity" || page == "Case" ? selectStageType.id == "all" : false')
                v-icon(size='16').mr-2 fas fa-file-download
                span {{$t('IMPORT_EXPORT.EXPORT')}}
            
            v-dialog(v-model='exportDialog' max-width='500' v-if='exportDialog')
                export-dialog(@emitExport='onExport' @emitEditFilter='onEmitEditFilter' :page_name='page' :stage_type='page == "Opportunity" || page == "Case" ? selectStageType: null' :search='search' :filter_id='filter' :tab='tab' :owner='owner' :export_activity="export_activity")
            v-dialog(v-model='importDialog' max-width='600' v-if='importDialog')
                import-dialog(@emitImport='onImport' :page_name='page' :stage_type='page == "Opportunity" || page == "Case" ? selectStageType.id: null')
            v-dialog(v-model='importFlexfieldDialog' max-width='500' v-if='importFlexfieldDialog')
                import-flexfield-dialog(@emitImport='onImport' :customerAttributeList='customerAttributeList' :currentAttributeId='currentAttributeId' @emitExport='onExport' :page_name='page')
            v-dialog(v-model='exportFlexfieldDialog' max-width='500' v-if='exportFlexfieldDialog')
                export-flexfield-dialog(:customerAttributeList='customerAttributeList' :currentAttributeId='currentAttributeId' @emitExport='onExport' @emitEditFilter='onEmitEditFilter' :page_name='page' :search='search' :owner='owner')
        div(v-if='!isWiadvance() && (isStaff || page == "Activity")')
            v-menu(v-if='page!="Activity"' offset-y)
                template(v-slot:activator="{ on, attrs }")
                    v-btn.ml-3.pa-0(text height='38' v-bind="attrs" v-on="on" color='gary' :disabled='page == "Opportunity" || page == "Case" ? selectStageType.id == "all" : false')
                        v-icon(size='16').mr-2 fas fa-file-upload
                        span {{$t('IMPORT_EXPORT.IMPORT')}}
                v-list
                    v-list-item(@click='importData') {{ $t('IMPORT_EXPORT.TITLE') }}
                    v-list-item(@click='downloadTemp') {{ $t('IMPORT_EXPORT.DOWNLOAD_TEMP') }}
            v-btn.ml-3.pa-0(text height='38' color='gary' @click='exportData' :disabled='page == "Opportunity" || page == "Case" ? selectStageType.id == "all" : false')
                v-icon(size='16').mr-2 fas fa-file-download
                span {{$t('IMPORT_EXPORT.EXPORT')}}
            
            v-dialog(v-model='exportDialog' max-width='500' v-if='exportDialog')
                export-dialog(@emitExport='onExport' @emitEditFilter='onEmitEditFilter' :page_name='page' :stage_type='page == "Opportunity" || page == "Case" ? selectStageType: null' :search='search' :filter_id='filter' :tab='tab' :owner='owner' :export_activity="export_activity")
            v-dialog(v-model='importDialog' max-width='600' v-if='importDialog')
                import-dialog(@emitImport='onImport' :page_name='page' :stage_type='page == "Opportunity" || page == "Case" ? selectStageType.id: null')
            v-dialog(v-model='importFlexfieldDialog' max-width='500' v-if='importFlexfieldDialog')
                import-flexfield-dialog(@emitImport='onImport' :customerAttributeList='customerAttributeList' :currentAttributeId='currentAttributeId' @emitExport='onExport' :page_name='page')
            v-dialog(v-model='exportFlexfieldDialog' max-width='500' v-if='exportFlexfieldDialog')
                export-flexfield-dialog(:customerAttributeList='customerAttributeList' :currentAttributeId='currentAttributeId' @emitExport='onExport' @emitEditFilter='onEmitEditFilter' :page_name='page' :search='search' :owner='owner')
</template>
<script>

import exportFlexfieldDialog from '@/components/Common/exportFlexfieldDialog';
import importFlexfieldDialog from '@/components/Common/importFlexfieldDialog';
import exportDialog from '@/components/Common/exportDialog';
import importDialog from '@/components/Common/importDialog';
import ResourceDataService from "@/services/ResourceDataService";
import errorDialog from '@/components/Dialog/errorDialog';
import i18n from '/common/plugins/vue-i18n.js';

export default {
    components: {
        errorDialog,
        exportDialog,
        importDialog,
        exportFlexfieldDialog,
        importFlexfieldDialog
    },
    props: {
        page: {
            type: String,
            required: true,
        },
        selectStageType: {
            type: Object,
            required: false,
        },
        search: {
            type: String,
            required: false,
        },
        filter: {
            required: false,
        },
        tab: {
            required: false,
        },
        owner: {
            required: false,
        },
        export_activity: {
            required: false,
        },
        customerAttributeList:{
            type:Array,
            require:false
        },
        currentAttributeId:{
            type:Number,
            require:false
        },
    },
    data() {
        return {
            // dialog
            errorDialog: false,
            errorMessage: '',
            exportDialog: false,
            importDialog: false,
            exportFlexfieldDialog: false,
            importFlexfieldDialog: false,
            // other
            isStaff: false,
        }
    },
    created(){
        const USER = 'user';
        let userJson = window.localStorage.getItem(USER);
        let user = JSON.parse(userJson);
        user.is_staff ? this.isStaff = true : this.isStaff = false;
    },
    methods: {
        exportData(){
            // this.$emit("EmitExport");
            if(this.page == "Flexfield"){
                this.exportFlexfieldDialog = true;
            }
            else{
                this.exportDialog = true;
            }
        },
        importData(){
            // this.$emit("EmitImport");
            if(this.page == "Flexfield"){
                this.importFlexfieldDialog = true;
            }
            else{
                this.importDialog = true;
            }
        },
        onExport(){
            this.exportDialog = false;
            this.exportFlexfieldDialog = false;
        },
        onImport(){
            this.importDialog = false;
            this.importFlexfieldDialog = false;
        },
        onEmitEditFilter(editedData){
            this.$emit('emitFilter', editedData, 'confirmEdit_exportDialog')
        },
        downloadFile(response){
            if ('download' in document.createElement('a')) {
                let link = document.createElement('a')
                let now = new Date()
                let yyyy = now.getFullYear()
                let MM = (now.getMonth()+1).toString().padStart(2,'0')
                let dd = now.getDate().toString().padStart(2,'0')
                let hh = now.getHours().toString().padStart(2,'0')
                let mm = now.getMinutes().toString().padStart(2,'0')
                let ss = now.getSeconds().toString().padStart(2,'0')
                let currentTime = `${yyyy}-${MM}-${dd} ${hh}${mm}${ss}`
                let export_type = 'Template'
                link.style.display = 'none'
                let table_or_stage_name = ''
                link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(response.data)
                if(this.customerAttributeList && this.currentAttributeId){
                    table_or_stage_name = this.customerAttributeList.find(el => el.id == this.currentAttributeId).name;
                }else if(this.selectStageType){
                    table_or_stage_name = this.selectStageType.stage_type_name;
                }
                link.download = `${this.page} ${table_or_stage_name} [${export_type}] ${currentTime}.csv`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else {
                this.errorMessage = i18n.t('IMPORT_EXPORT.ERROR')
                this.errorDialog = true;
            }
        },
        downloadTemp(){
            if(this.page=="Flexfield"){
                ResourceDataService.export_flexfield(this.currentAttributeId,"",'temp')
                .then(response => {
                    this.downloadFile(response);
                    this.$emit("emitExport");
                    this.exporting = false;
                });
                return
            }
            let stageTypeId = null;
            this.page == 'Opportunity' || this.page == 'Case' ? stageTypeId = this.selectStageType.id : stageTypeId = null;
            ResourceDataService.export(this.page,'temp',stageTypeId)
            .then(response => {
                this.downloadFile(response);
            });
        }
    },
}
</script>